@import "/src/styles/abstracts/variables";
@import "/src/styles/abstracts/breakpoints";

.section {
  font-family: $spezia;
  height: 100%;
  margin: 0 auto;
  max-width: 1400px;
  padding: 4rem 0;
  position: relative;
  width: stretch;
  z-index: 1;

  @media (width <= 1440px) {
    padding: 4rem;
  }

  @include media-breakpoint-down(md) {
    padding: 4rem 2rem;
  }

  @include media-breakpoint-down(sm) {
    padding: 4rem 1.5rem;
  }
}
