@import "/src/styles/abstracts/variables";
@import "src/styles/abstracts/breakpoints";

.search-input {
  border-radius: 50px;
  box-shadow: 0 4px 15px 0 rgb(var(--secondary-rgb) / 20%);
  max-width: 600px;
  padding: 1rem 2rem 1rem 5rem;
  position: relative;
  width: 100%;

  @include media-breakpoint-down(md) {
    padding: 0.75rem 1.2rem 0.75rem 3rem;
    width: unset;
  }

  .icon {
    color: #9ea2a2;
    font-size: 1.75rem;
    left: 2rem;
    position: absolute;
    top: calc(50% - (1.75rem / 2));

    @include media-breakpoint-down(md) {
      font-size: 1.25rem;
      left: 1.1rem;
      top: calc(50% - (1.25rem / 2));
    }
  }

  .filter-icon {
    border-radius: 0.25rem;
    cursor: pointer;
    height: 2rem;
    position: absolute;
    right: 2rem;
    top: calc(50% - 1rem);
    transition: 250ms ease;
    width: 2rem;

    .active-filters {
      align-items: center;
      background-color: #2c3131;
      border-radius: 100%;
      color: #fff;
      display: flex;
      font-size: 0.75rem;
      font-weight: 500;
      height: 1rem;
      justify-content: center;
      position: absolute;
      right: -0.15rem;
      top: -0.2rem;
      width: 1rem;
    }

    svg {
      height: 100%;
      width: 100%;
    }

    &:hover {
      background-color: #f3f3f3;

      svg {
        circle {
          fill: #f3f3f3;
        }
      }
    }
  }

  input {
    background-color: var(--primary);
    border: 0;
    font-family: $spezia;
    font-size: 1.75rem;
    font-weight: 400;
    padding: 0;
    width: 100%;

    @include media-breakpoint-down(md) {
      font-size: 1.25rem;
    }

    &:focus-visible {
      outline: none;
    }

    &::placeholder {
      color: #9ea3a2;
    }
  }
}
