@import "/src/styles/base/main";

.trial {
  border: 2px solid;
  border-radius: 10px;
  margin-bottom: 2rem;
  overflow: hidden;
  position: relative;
  transition:
    padding-bottom 500ms ease,
    box-shadow 500ms ease;

  .information {
    background-color: var(--primary);
    border-bottom: 0 solid;
    cursor: pointer;
    display: flex;
    padding: 2rem;
    position: relative;
    transition: border-bottom 0ms ease 500ms;
    z-index: 1;

    @include media-breakpoint-down(sm) {
      padding: 1rem;
    }

    .drop-arrow {
      font-size: 1.25rem;
      position: absolute;
      right: 1.5rem;
      top: 1.5rem;
      transition: transform 500ms ease-in-out;

      &.open {
        transform: rotate(-180deg);
      }

      @include media-breakpoint-up(sm) {
        display: none;
      }
    }

    .organization-logo {
      align-self: flex-start;
      filter: brightness(0);
      height: 40px;
      width: auto;

      @include media-breakpoint-down(sm) {
        height: 30px;
      }
    }

    .trial-image {
      border-radius: 6px;
      height: 230px;
      margin-left: auto;
      overflow: hidden;
      position: relative;
      width: calc(100% - 2rem);

      img {
        object-fit: cover;
      }
    }
  }

  .criteria {
    background-color: var(--primary);
    bottom: 0;
    left: 0;
    padding: 2rem;
    position: absolute;
    width: calc(100% - 4rem);
    z-index: 0;

    @include media-breakpoint-down(sm) {
      padding: 1rem;
      width: calc(100% - 2rem);
    }

    ul {
      margin: 0 0 1rem;
      padding-left: 1.5rem;
    }

    p {
      margin: 0 0 1rem;
    }

    button {
      @include media-breakpoint-down(md) {
        width: 100%;
      }
    }
  }

  &.opened {
    box-shadow: 5px 5px 0 0 var(--secondary);

    .description {
      -webkit-line-clamp: unset;
    }

    .information {
      border-bottom: 2px solid;
      transition: border-bottom 0ms ease;
    }
  }
}

:global(.enable-dark) .trial {
  @media (prefers-color-scheme: dark) {
    .organization-logo {
      filter: brightness(0) invert(1);
    }
  }
}
