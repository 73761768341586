/* Colors */
$black: #000;
$midnight: #171e2d;
$white: #fff;
$light-gray: #e8e8e8;
$gray: #626363;

/* Fonts */
$spezia: "Spezia", san-serif;

/* custom breakpoints */
$grid-breakpoints: (
  xs: 0,
  sm: 576px,
  md: 768px,
  lg: 992px,
  xl: 1200px,
  xxl: 1440px,
);
$container-max-widths: (
  sm: 540px,
  md: 720px,
  lg: 960px,
  xl: 1140px,
  xxl: 1390px,
);
