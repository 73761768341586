// Spezia - 200, 300, 400, 500, 600, 700, 800, 900 (regular/italic)

@font-face {
  font-display: swap;
  font-family: Spezia;
  font-style: normal;
  font-weight: 200;
  src:
    local("Spezia"),
    url("https://cdn.anivive.com/raw/upload/fonts/spezia/SpeziaWeb-Thin.woff")
      format("woff"),
    url("https://cdn.anivive.com/raw/upload/fonts/spezia/SpeziaWeb-Thin.woff2")
      format("woff2");
}

@font-face {
  font-display: swap;
  font-family: Spezia;
  font-style: italic;
  font-weight: 200;
  src:
    local("Spezia"),
    url("https://cdn.anivive.com/raw/upload/fonts/spezia/SpeziaWeb-ThinItalic.woff")
      format("woff"),
    url("https://cdn.anivive.com/raw/upload/fonts/spezia/SpeziaWeb-ThinItalic.woff2")
      format("woff2");
}

@font-face {
  font-display: swap;
  font-family: Spezia;
  font-style: normal;
  font-weight: 300;
  src:
    local("Spezia"),
    url("https://cdn.anivive.com/raw/upload/fonts/spezia/SpeziaWeb-Light.woff")
      format("woff"),
    url("https://cdn.anivive.com/raw/upload/fonts/spezia/SpeziaWeb-Light.woff2")
      format("woff2");
}

@font-face {
  font-display: swap;
  font-family: Spezia;
  font-style: italic;
  font-weight: 300;
  src:
    local("Spezia"),
    url("https://cdn.anivive.com/raw/upload/fonts/spezia/SpeziaWeb-LightItalic.woff")
      format("woff"),
    url("https://cdn.anivive.com/raw/upload/fonts/spezia/SpeziaWeb-LightItalic.woff2")
      format("woff2");
}

@font-face {
  font-display: swap;
  font-family: Spezia;
  font-style: normal;
  font-weight: 400;
  src:
    local("Spezia"),
    url("https://cdn.anivive.com/raw/upload/fonts/spezia/SpeziaWeb-Book.woff")
      format("woff"),
    url("https://cdn.anivive.com/raw/upload/fonts/spezia/SpeziaWeb-Book.woff2")
      format("woff2");
}

@font-face {
  font-display: swap;
  font-family: Spezia;
  font-style: italic;
  font-weight: 400;
  src:
    local("Spezia"),
    url("https://cdn.anivive.com/raw/upload/fonts/spezia/SpeziaWeb-BookItalic.woff")
      format("woff"),
    url("https://cdn.anivive.com/raw/upload/fonts/spezia/SpeziaWeb-BookItalic.woff2")
      format("woff2");
}

@font-face {
  font-display: swap;
  font-family: Spezia;
  font-style: normal;
  font-weight: 500;
  src:
    local("Spezia"),
    url("https://cdn.anivive.com/raw/upload/fonts/spezia/SpeziaWeb-Regular.woff")
      format("woff"),
    url("https://cdn.anivive.com/raw/upload/fonts/spezia/SpeziaWeb-Regular.woff2")
      format("woff2");
}

@font-face {
  font-display: swap;
  font-family: Spezia;
  font-style: italic;
  font-weight: 500;
  src:
    local("Spezia"),
    url("https://cdn.anivive.com/raw/upload/fonts/spezia/SpeziaWeb-RegularItalic.woff")
      format("woff"),
    url("https://cdn.anivive.com/raw/upload/fonts/spezia/SpeziaWeb-RegularItalic.woff2")
      format("woff2");
}

@font-face {
  font-display: swap;
  font-family: Spezia;
  font-style: normal;
  font-weight: 600;
  src:
    local("Spezia"),
    url("https://cdn.anivive.com/raw/upload/fonts/spezia/SpeziaWeb-Medium.woff")
      format("woff"),
    url("https://cdn.anivive.com/raw/upload/fonts/spezia/SpeziaWeb-Medium.woff2")
      format("woff2");
}

@font-face {
  font-display: swap;
  font-family: Spezia;
  font-style: italic;
  font-weight: 600;
  src:
    local("Spezia"),
    url("https://cdn.anivive.com/raw/upload/fonts/spezia/SpeziaWeb-MediumItalic.woff")
      format("woff"),
    url("https://cdn.anivive.com/raw/upload/fonts/spezia/SpeziaWeb-MediumItalic.woff2")
      format("woff2");
}

@font-face {
  font-display: swap;
  font-family: Spezia;
  font-style: normal;
  font-weight: 700;
  src:
    local("Spezia"),
    url("https://cdn.anivive.com/raw/upload/fonts/spezia/SpeziaWeb-SemiBold.woff")
      format("woff"),
    url("https://cdn.anivive.com/raw/upload/fonts/spezia/SpeziaWeb-SemiBold.woff2")
      format("woff2");
}

@font-face {
  font-display: swap;
  font-family: Spezia;
  font-style: italic;
  font-weight: 700;
  src:
    local("Spezia"),
    url("https://cdn.anivive.com/raw/upload/fonts/spezia/SpeziaWeb-SemiBoldItalic.woff")
      format("woff"),
    url("https://cdn.anivive.com/raw/upload/fonts/spezia/SpeziaWeb-SemiBoldItalic.woff2")
      format("woff2");
}

@font-face {
  font-display: swap;
  font-family: Spezia;
  font-style: normal;
  font-weight: 800;
  src:
    local("Spezia"),
    url("https://cdn.anivive.com/raw/upload/fonts/spezia/SpeziaWeb-Bold.woff")
      format("woff"),
    url("https://cdn.anivive.com/raw/upload/fonts/spezia/SpeziaWeb-Bold.woff2")
      format("woff2");
}

@font-face {
  font-display: swap;
  font-family: Spezia;
  font-style: italic;
  font-weight: 800;
  src:
    local("Spezia"),
    url("https://cdn.anivive.com/raw/upload/fonts/spezia/SpeziaWeb-BoldItalic.woff")
      format("woff"),
    url("https://cdn.anivive.com/raw/upload/fonts/spezia/SpeziaWeb-BoldItalic.woff2")
      format("woff2");
}

@font-face {
  font-display: swap;
  font-family: Spezia;
  font-style: normal;
  font-weight: 900;
  src:
    local("Spezia"),
    url("https://cdn.anivive.com/raw/upload/fonts/spezia/SpeziaWeb-Black.woff")
      format("woff"),
    url("https://cdn.anivive.com/raw/upload/fonts/spezia/SpeziaWeb-Black.woff2")
      format("woff2");
}

@font-face {
  font-display: swap;
  font-family: Spezia;
  font-style: italic;
  font-weight: 900;
  src:
    local("Spezia"),
    url("https://cdn.anivive.com/raw/upload/fonts/spezia/SpeziaWeb-BlackItalic.woff")
      format("woff"),
    url("https://cdn.anivive.com/raw/upload/fonts/spezia/SpeziaWeb-BlackItalic.woff2")
      format("woff2");
}
