@import "src/styles/base/main";

.card {
  border: 2px solid;
  border-radius: 1rem;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin: 0 1rem 2rem;
  padding: 1rem;
  transition: box-shadow 250ms ease-in-out;

  @include media-breakpoint-down(xxl) {
    margin: 0 0.75rem 1.5rem;
  }

  @include media-breakpoint-down(sm) {
    margin: 0 0 1.5rem;
  }

  .image {
    border-radius: 0.5rem;
    height: 8rem;
    margin-bottom: 1.5rem;
    overflow: hidden;
    position: relative;

    img {
      object-fit: cover;
    }
  }

  a {
    border: 0;
    border-radius: 0.5rem;
  }

  &:hover {
    box-shadow: 5px 5px 0 0 var(--secondary);
  }
}
