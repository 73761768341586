@import "/src/styles/base/main";

.recommended-trials {
  .cards {
    display: grid;
    grid-auto-rows: auto;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    margin-bottom: 1.5rem;
    overflow: hidden;

    @include media-breakpoint-down(xl) {
      grid-template-columns: 1fr 1fr 1fr;
      grid-template-rows: 1fr;
    }

    @include media-breakpoint-down(lg) {
      grid-template-columns: 1fr 1fr;
      grid-template-rows: 1fr 1fr;
    }

    @include media-breakpoint-down(sm) {
      grid-template-columns: 1fr;
      grid-template-rows: 1fr 1fr 1fr 1fr;
    }
  }
}
