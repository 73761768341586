.change-page {
  align-items: center;
  border: 2px solid;
  border-radius: 6px 0 0 6px;
  cursor: pointer;
  display: flex;
  height: 35px;
  justify-content: center;
  width: 35px;

  &:last-child {
    border-radius: 0 6px 6px 0;
  }

  &.disabled {
    cursor: not-allowed;
    opacity: 0.3;
  }
}
