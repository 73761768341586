@import "src/styles/abstracts/breakpoints";

.hero-image {
  height: 500px;
  margin-top: 4.5rem;
  max-width: 1920px;
  position: relative;
  width: 100%;

  @include media-breakpoint-down(xl) {
    height: 400px;
  }

  @include media-breakpoint-down(lg) {
    height: 300px;
  }

  @include media-breakpoint-down(sm) {
    height: 200px;
  }

  img {
    object-fit: cover;
    object-position: center bottom;

    @include media-breakpoint-down(sm) {
      object-position: 65% bottom;
    }
  }
}
