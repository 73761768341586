@import "/src/styles/base/main";

.mobile-background-cover {
  background-color: $black;
  height: 100vh;
  left: 0;
  opacity: 0;
  pointer-events: none;
  position: fixed;
  top: 0;
  transition: opacity 500ms ease;
  width: 100vw;
  z-index: 1;

  &.show {
    opacity: 0.3;
    pointer-events: auto;
    touch-action: none;
  }
}

.mobile-filter {
  background-color: var(--primary);
  border-radius: 1.5rem 1.5rem 0 0;
  display: flex;
  flex-direction: column;
  left: 0;
  max-height: calc(100vh - 12rem);
  position: fixed;
  transition: bottom 500ms ease;
  width: 100%;
  z-index: 2;

  > div {
    padding: 1.5rem 1.5rem 5.5rem;
  }

  h3 {
    font-size: 1.5rem;
    font-weight: 600;
    margin: 0;
  }

  .button-container {
    align-items: center;
    background-color: #fff;
    bottom: 0;
    box-shadow: 0 -10px 10px #fff;
    box-sizing: border-box;
    display: flex;
    justify-content: space-between;
    padding: 1.5rem;
    position: absolute;
    width: 100%;

    .clear {
      cursor: pointer;
      padding: 0.5rem 1rem;

      &.disabled {
        opacity: 0.5;
        pointer-events: none;
      }
    }
  }

  .category {
    align-items: center;
    border-bottom: 2px solid;
    display: flex;
    margin-bottom: 1rem;
    padding-bottom: 0.5rem;

    .icon {
      width: 2.25rem;
    }
  }

  .filters-container {
    height: 25rem;
    overflow-y: scroll;
    width: 100%;

    .filter {
      align-items: center;
      cursor: pointer;
      display: flex;
      margin: 0 0 1rem 2.25rem;

      &:last-child {
        margin-bottom: 0;
      }

      .checkbox {
        align-items: center;
        background-color: #fff;
        border: 2px solid #8c9196;
        border-radius: 0.25rem;
        color: $white;
        display: flex;
        height: 1.25rem;
        justify-content: center;
        margin-right: 0.5rem;
        transition: 150ms ease;
        width: 1.25rem;

        &.selected {
          background-color: #2c6ecb;
          border: 2px solid #2c6ecb;
        }
      }
    }
  }
}
