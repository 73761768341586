@import "/src/styles/abstracts/variables";
@import "src/styles/abstracts/breakpoints";

.filter {
  .filter-types {
    .category {
      border-bottom: 2px solid #dcdcdc;
      color: #464d4c;
      cursor: pointer;
      padding: 0 1rem 0.5rem;
      transition: border-bottom 200ms ease;

      h3 {
        font-weight: 600;
      }

      &:hover {
        .icon {
          background-color: #000;

          svg {
            color: #fff;
          }
        }
      }

      &.selected {
        border-color: #000;
        color: #000;

        h3 {
          font-weight: 700;
        }
      }
    }
  }

  .filter-option {
    background-color: var(--primary);
    border: 2px solid #e5e9e8;
    border-radius: 40px;
    cursor: pointer;
    margin: 0 0.5rem;
    padding: 0.5rem 1rem;
    white-space: nowrap;

    &:first-child {
      margin-left: 0;
    }

    &:last-child {
      margin-right: 0;
    }

    &:hover {
      background-color: #2c3131;
      border-color: #2c3131;
      color: #fff;
    }

    span {
      white-space: nowrap;
    }

    &.active {
      background-color: #2c3131;
      border-color: #2c3131;
      color: #fff;
    }
  }
}
