.html {
  p {
    margin: 0;
  }

  span {
    white-space: nowrap;

    sup {
      font-size: 0.6rem;
      font-weight: 400;
      margin-left: -0.15rem;
      margin-right: 0.15rem;

      + span {
        margin-left: -0.3rem;
      }
    }
  }
}
