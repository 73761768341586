// 200 - thin
// 300 - light
// 400 - book
// 500 - regular
// 600 - medium
// 700 - semibold
// 800 - bold
// 900 - black
@for $i from 2 to 9 {
  .weight--#{calc($i * 100)} {
    font-weight: calc($i * 100) !important;
  }
}

@for $i from 0 to 6 {
  .clamp--#{$i} {
    overflow: hidden;
    text-overflow: ellipsis;
    // stylelint-disable-next-line
    display: -webkit-box;
    -webkit-line-clamp: $i;
    // stylelint-disable-next-line
    -webkit-box-orient: vertical;
  }
}

.box-sizing {
  &--border {
    box-sizing: border-box;
  }

  &--content {
    box-sizing: content-box;
  }
}

.cursor {
  &--default {
    cursor: default !important;
  }

  &--pointer {
    cursor: pointer !important;
  }

  &--not-allowed {
    cursor: not-allowed !important;
  }

  &--grab {
    cursor: grab !important;

    &:active {
      cursor: grabbing !important;
    }
  }
}
