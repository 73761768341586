/* TODO: double check font weights match w/ typography source */

@import "~bootstrap/scss/vendor/rfs";

.text-style-h1 {
  @include font-size(96px);
  @include rfs(116px, line-height);

  font-weight: 700; /* 700 - semibold */
  letter-spacing: -0.18rem;
  margin: 0;
}

.text-style-h2 {
  @include font-size(60px);
  @include rfs(72px, line-height);

  font-weight: 600; /* 600 - medium */
  letter-spacing: -0.1125rem;
  margin: 0;
}

.text-style-h3 {
  @include font-size(48px);
  @include rfs(60px, line-height);

  font-weight: 600; /* 600 - medium */
  letter-spacing: -0.09rem;
  margin: 0;
}

.text-style-h4 {
  @include font-size(32px);
  @include rfs(40px, line-height);

  font-weight: 700; /* 700 - semibold */
  letter-spacing: -0.06rem;
  margin: 0;
}

.text-style-h5 {
  @include font-size(24px);
  @include rfs(28px, line-height);

  font-weight: 700; /* 700 - semibold */
  letter-spacing: -0.0225rem;
  margin: 0;
}

.text-style-h5-regular {
  @include font-size(24px);
  @include rfs(28px, line-height);

  font-weight: 500; /* 500 - regular */
  letter-spacing: -0.045rem;
  margin: 0;
}

.text-style-h6 {
  @include font-size(20px);
  @include rfs(24px, line-height);

  font-weight: 700; /* 700 - semibold */
  letter-spacing: -0.0125rem;
  margin: 0;
}

.text-style-h6-regular {
  @include font-size(20px);
  @include rfs(24px, line-height);

  font-weight: 500; /* 500 - regular */
  letter-spacing: -0.0125rem;
  margin: 0;
}

.text-style-body1-bold {
  @include font-size(16px);
  @include rfs(22px, line-height);

  font-weight: 700; /* 700 - semibold */
  margin: 0;
}

.text-style-body1-regular {
  @include font-size(16px);
  @include rfs(22px, line-height);

  font-weight: 500; /* 500 - regular */
  letter-spacing: -0.02rem;
  margin: 0;
}

.text-style-body2-bold {
  @include font-size(14px);
  @include rfs(18px, line-height);

  font-weight: 600; /* 600 - medium */
  margin: 0;
}

.text-style-body2-regular {
  @include font-size(14px);
  @include rfs(18px, line-height);

  font-weight: 500; /* 500 - regular */
  margin: 0;
}

.text-style-caption-bold {
  @include font-size(12px);
  @include rfs(14px, line-height);

  font-weight: 700; /* 700 - semibold */
  margin: 0;
}

.text-style-caption-regular {
  @include font-size(12px);
  @include rfs(14px, line-height);

  font-weight: 500; /* 500 - regular */
  margin: 0;
}

.text-style-monospace {
  @include font-size(12px);
  @include rfs(14px, line-height);

  font-weight: 500; /* 500 - regular */
  margin: 0;
  text-transform: uppercase;
}

.color {
  &--primary {
    color: var(--primary);
  }

  &--secondary {
    color: var(--secondary);
  }

  &--tertiary {
    color: var(--tertiary);
  }
}
